<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar
      v-model="isSnackbarCenteredVisible"
      :light="$vuetify.theme.dark"
      centered
      color="error"
      timeout="3000"
    >
      {{snackbarMessage}}
    </v-snackbar>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold brand-name">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>
                        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-center">
            Войти с помощью
          </p>
        </v-card-text>
        <!-- social links -->
        <v-card-text class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-5"
            @click.prevent="handleSignupWindow(link.name)"
          >
            <v-icon large :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
                <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">Или</span>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Почта"
              placeholder="Почта"
              hide-details
              class="mb-3"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Пароль"
              placeholder="Пароль"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :error-messages="errorMessages.password"
              :rules="[validators.required]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="mt-1"
              >
                Забыли пароль?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              Войти
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Ещё не зарегистрировался?
          </span>
          <router-link :to="{name:'auth-register'}">
            Создать аккаунт
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGoogle, mdiMicrosoft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect, OAuthProvider, TwitterAuthProvider, getRedirectResult } from 'firebase/auth'
import store from '@/store'
import { useRouter } from '@core/utils'

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Login'
  },
  setup() {
    const loginForm = ref(null)
    const isSnackbarCenteredVisible = ref(false)
    const snackbarMessage = ref(null)
    const isPasswordVisible = ref(false)
    const auth = getAuth();
    auth.languageCode = 'ru';
    const { router } = useRouter()
    const email = ref()
    const password = ref()
    const errorMessages = ref([])
    const provider = {
      google: new GoogleAuthProvider(),
      facebook: new FacebookAuthProvider(),
      microsoft:  new OAuthProvider('microsoft.com'),
      twitter: new TwitterAuthProvider(),
    }


    const socialLink = computed(() => {
      let socialLinkArr = [{
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
          name: 'facebook'
        },
        {
          icon: mdiMicrosoft,
          color: '#737373',
          colorInDark: '#1da1f2',
          name: 'microsoft'
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
          name: 'twitter'
        }]
        if( !window.navigator.userAgent.includes('Instagram') && !window.navigator.userAgent.includes('FBAV') && !window.navigator.userAgent.includes('FBAN')) {
          socialLinkArr.unshift({
            icon: mdiGoogle,
            color: '#db4437',
            colorInDark: '#db4437',
            name: 'google'
          })
        }
        return socialLinkArr;
    })

    const handleFormSubmit = () => {
      // store.dispatch('getCurrentUser')
      if( loginForm.value.validate() ) {
        signInWithEmailAndPassword(auth, email.value, password.value)
          .then(() => {
            // Signed in
            if(localStorage.redirecUri) {
              let redirecUri = localStorage.redirecUri
              localStorage.removeItem("redirecUri");
              router.push({ path: redirecUri })
            } else {
              router.push({ name: 'home' })
            }
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            switch( errorCode ) {
              case 'auth/user-not-found':
                snackbarMessage.value = 'Пользователь не найден';
                isSnackbarCenteredVisible.value = true;
                break;
              case 'auth/wrong-password':
                snackbarMessage.value = 'Неверный пароль';
                isSnackbarCenteredVisible.value = true;
                break;
              default:
                snackbarMessage.value = 'Вход в систему не удался';
                isSnackbarCenteredVisible.value = true;
            }
          });
      }
    }

    const handleSignupWindow = (name) => {
      signInWithRedirect(auth, provider[name])
      console.log(navigator.userAgent)
    }

    getRedirectResult(auth)
      .then((result) => {
        // The signed-in user info.
        store.dispatch("user/getCurrentUser");
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode == 'auth/account-exists-with-different-credential') {
          snackbarMessage.value = 'Электронная почта зарегистрирована у другого провайдера. Пожалуйста, выберите другой';
          isSnackbarCenteredVisible.value = true;
        }
        // ...
      });
    return {
      handleFormSubmit,
      handleSignupWindow,
      snackbarMessage,
      isSnackbarCenteredVisible,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      loginForm,
    }
  },
  mounted() {
    localStorage.removeItem("products");
    localStorage.removeItem("weight_loss_marathon_1_days");
    localStorage.removeItem("booty_gain_marathon_1_days");
  }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
